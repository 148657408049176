import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {IncomeCall} from "../../components/incomeCall";
import {CallHistory} from "../../components/CallHistory";
import Statuses from "../../components/statuses";
import axios from "axios";
import LogoutButton from "../../components/logoutButton";
import {apiConfig} from "../../config";
import useWebSocket from 'react-use-websocket';
import Header from "../../components/header";
import MainMenu from "../../components/MainMenu";
import {fakeAuthProvider} from "../../components/auth/auth";
import {useNavigate} from "react-router-dom";
import styles from "./index.module.css";
import AudioTests from "../../components/AudioTests";
import * as Sentry from "@sentry/react";


let audio_src_start = "https://cdn.ugravity.org/hh-resumes/0000135.mp3"
let audio_src_end = "https://cdn.ugravity.org/hh-resumes/02442.mp3"


const ProtectedPage: React.FC = () => {
    const WS_URL = apiConfig.wsUrl;

    const [selectedStatus, setSelectedStatus] = useState('Отошел');
    const [callSession, setCallSession] = useState(null)
    const [projectName, setProjectName]: [any, any] = useState(null)
    // const [ws, setWs] = useState(new WebSocket(WS_URL))
    const [startTime2, setStartTime2] = useState(234)
    const [userStat, setUserStat]: [any, any] = useState(null)

    const [audio1, setAudio1] = useState(new Audio(audio_src_start))
    const [audio2, setAudio2] = useState(new Audio(audio_src_end))

    const navigate = useNavigate()

    const Verify = async () => {
        const config = {
            method: 'get',
            url: `${apiConfig.url}/auth/verify`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            const response = await axios(config);
            console.log("VERIFY", response.data)
            return response.data.projects
        } catch (error) {
            await fakeAuthProvider.signout();
            navigate(0)
            return navigate("/");
        }
    }

    const getUserStatistic = async () => {
        const config = {
            method: 'get',
            url: `${apiConfig.url}/statistic/user`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            const response = await axios(config);
            console.log(response.data)
            setUserStat(response.data)
        } catch (error) {
            console.log("ERROR")
        }
    }

    const getUserTask = async () => {
        const config = {
            method: 'get',
            url: `${apiConfig.url}/project/task`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            const response = await axios(config);
            console.log("TASK_URL", response.data)

            if (response.data) {
                window.open(response.data.lead_url)
            }
        } catch (error) {
            console.log("ERROR")
        }
    }

    // useEffect(() => {
    //
    // }, []);

    // window.addEventListener('beforeunload', () => {
    //     sendCallStatus("MOVED_AWAY")
    // })

    const clientRef: any = useRef(null);
    const [waitingToReconnect, setWaitingToReconnect]: [any, any] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (waitingToReconnect) {
            return;
        }

        if (!clientRef.current) {
            const client = new WebSocket(WS_URL);
            clientRef.current = client;

            (window as any).client = client;

            client.onerror = (e) => {
                Sentry.captureMessage(
                    `WS ERROR ${localStorage.getItem("user_name")}`
                );
                console.error(e)
            };

            client.onopen = () => {
                setIsOpen(true);
                Sentry.captureMessage(
                    `WS OPEN ${localStorage.getItem("user_name")}`
                );
                console.log('ws opened');
                client.send(`${localStorage.getItem("user_id")}`)
            };

            client.onclose = () => {
                if (clientRef.current) {
                    Sentry.captureMessage(
                        `CLOSE CONNECTION WS ${localStorage.getItem("user_name")}`
                    );
                    console.log('ws closed by server');
                } else {
                    Sentry.captureMessage(
                        `CLOSE CONNECTION WS BY APP ${localStorage.getItem("user_name")}`
                    );
                    console.log('ws closed by app component unmount');
                }

                if (waitingToReconnect) {
                    return;
                };

                setIsOpen(false);
                console.log('ws closed');

                setWaitingToReconnect(true);

                setTimeout(() => setWaitingToReconnect(null), 2000);
            };

            client.onmessage = message => {
                console.log('received message', message);
            };

            return () => {

                console.log('Cleanup');
                // Dereference, so it will set up next time
                clientRef.current = null;

                client.close();
            }
        }

    }, [waitingToReconnect]);

    useEffect(() => {
        Verify()
        getUserStatistic()
        setProjectName(localStorage.getItem('user_in_project'))

        let is_permit = check_window_permission()
        navigator.mediaDevices.getUserMedia({audio: true, video: false}).catch((error) => {alert("Разрешите использование микрофона в браузере")});

        if (!is_permit) {
            alert("Разрешите открытие окон в браузере")
        }
    }, []);

    useEffect(() => {
        getUserStatistic()
    }, [selectedStatus]);

    useEffect(() => {
        const interval = setInterval(() => {
            getUserTask()
        }, 10000);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    // useEffect(() => {
    //     if (userStat) {
    //         if (selectedStatus == 'Отошел') {
    //             userStat.moved_away = timeFromTimer / 100
    //         }
    //         if (selectedStatus == 'Заполнение лида') {
    //             userStat.wait_lead = timeFromTimer / 100
    //         }
    //         if (selectedStatus == 'Обучение') {
    //             userStat.education = timeFromTimer / 100
    //         }
    //         if (selectedStatus == 'Готов') {
    //             userStat.ready = timeFromTimer / 100
    //         }
    //     }
    // }, [timeFromTimer]);

    return (
        <div className={styles.main_div}>
            <Header operator_page={localStorage.getItem("user_type") === "OPERATOR"} title={"Страница оператора"}/>
            <div className={styles.under_header_div}>
                {localStorage.getItem("user_type") === "ADMIN" && <MainMenu />}
                <div
                    className={
                    localStorage.getItem("user_type") === "ADMIN" ? styles.objects_container_admin : styles.objects_container_operator
                }>
                    <div className={styles.user_container}>
                        <div className={styles.stat_container}>
                            <div className={styles.user_name}>{localStorage.getItem("user_name")}</div>
                            {userStat &&
                            <div className={styles.stat_history_container}>
                                <div className={styles.stat_name}>Статистика</div>
                                <ul>
                                    <li>
                                        <div>Отошел:</div>
                                        {selectedStatus == 'Отошел' ?
                                            <Timer
                                                startTime={userStat.moved_away}
                                            /> : secondsToTime(userStat.moved_away)}
                                    </li>
                                    <li>
                                        <div>В звонке:</div>
                                        {selectedStatus == 'В звонке' ?
                                            <Timer
                                                startTime={userStat.in_call}
                                            /> : secondsToTime(userStat.in_call)}
                                    </li>
                                    <li>
                                        <div>Обучение:</div>
                                        {selectedStatus == 'Обучение' ?
                                            <Timer
                                                startTime={userStat.education}
                                            /> : secondsToTime(userStat.education)}
                                    </li>
                                    <li>
                                        <div>Заполнение лида:</div>
                                        {selectedStatus == 'Заполнение лида' ?
                                            <Timer
                                                startTime={userStat.wait_lead}
                                            /> : secondsToTime(userStat.wait_lead)}
                                    </li>
                                    <li>
                                        <div>Всего на смене:</div>
                                        {secondsToTime(userStat.all_time)}
                                    </li>
                                    <li>
                                        <div>В работе:</div>
                                        {secondsToTime(userStat.in_work)}
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <div>Звонков:</div>
                                        <div>{userStat.all_calls}</div>
                                    </li>
                                    <li>
                                        <div>Лидов:</div>
                                        <div>{userStat.leads}</div>
                                    </li>
                                </ul>
                            </div>}
                        </div>
                        <LogoutButton/>
                    </div>
                    <div className={styles.call_container}>
                        <Statuses data={{selectedStatus, setSelectedStatus}}/>
                        <AudioTests audio1={audio1} audio2={audio2}/>
                        <IncomeCall audio1={audio1} audio2={audio2} isCallActive={selectedStatus === 'Готов'} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus}/>
                        <CallHistory />
                    </div>
                </div>
            </div>
        </div>
            );
}

export default ProtectedPage


const check_window_permission = () => {
    const newWindow = window.open()
    console.log("A", newWindow)
    // проверить, что разрешение было получено
    if (newWindow) {
        // установить фокус на новое окно
        newWindow.close()

        return true
    } else {
        return false
    }
}


const Timer = forwardRef((props: any, parentRef) => {
    const [time, setTime] = useState(props.startTime * 100);

    // state to check stopwatch running or not
    const [isRunning, setIsRunning] = useState(true);

    useEffect(() => {
        let intervalId: any;
        if (isRunning) {
            // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
            intervalId = setInterval(() => {
                setTime(time + 100)
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [isRunning, time]);

    // useEffect(() => {
    //     reset()
    // }, [props.reset]);

    useImperativeHandle(parentRef, () => ({
        reset
    }), [])

    // Hours calculation
    const hours = Math.floor(time / 360000);

    // Minutes calculation
    const minutes = Math.floor((time % 360000) / 6000);

    // Seconds calculation
    const seconds = Math.floor((time % 6000) / 100);

    // Method to reset timer back to 0
    const reset = () => {
        setTime(0);
    };
    return (
        <div>
            {hours}:{minutes.toString().padStart(2, "0")}:
            {seconds.toString().padStart(2, "0")}
        </div>
    );
});


const secondsToTime = (time: number) => {
    time = time * 100

    const hours = Math.floor(time / 360000);

    // Minutes calculation
    const minutes = Math.floor((time % 360000) / 6000);

    // Seconds calculation
    const seconds = Math.floor((time % 6000) / 100);

    return (
        <div>
            {hours}:{minutes.toString().padStart(2, "0")}:
            {seconds.toString().padStart(2, "0")}
        </div>
    );
}