import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import Select from "react-select";
import {apiConfig} from "../../config";
import axios from "axios";
import {ClipLoader} from "react-spinners";


const UserCard = forwardRef((props: any, parentRef) => {
    const [options, setOptions]: [any, any] = useState([])
    const [allOptions, setAllOptions]: [any, any] = useState([])
    const [loading, setLoading] = useState(false)
    // const [toReset, setToReset] = useState(props.toReset)
    const timerRef = useRef<any>(null)
    const [startTime2, setStartTime2] = useState(props.user.seconds_last_status)

    useImperativeHandle(parentRef, () => ({
        resetTimer: timerRef.current ? timerRef.current.reset : null,
        setNewStatus: setNewStatus
    }), [timerRef])


    const setNewStatus = () => {
        console.log("UPDATE")
    }

    let color, word
    if (props.user.status === "MOVED_AWAY") {
        color = "#FF4646"
        word = "Отошел"
    }
    else if (props.user.status === "READY") {
        color = "#1BFF2A"
        word = "Готов"
    }
    else if (props.user.status === "EDUCATION") {
        color = "#C9BE09FF"
        word = "Обучение"
    }
    else if (props.user.status === "WAIT_LEAD") {
        color = "#FFB900FF"
        word = "Заполение лида"
    }
    else if (props.user.status === "IN_CALL") {
        color = "#00ff80"
        word = "Звонок"
    }
    else if (props.user.status === "OFFLINE") {
        color = "#FF4646"
        word = "Нет на месте"
    }
    else {
        color = "#42000b"
        word = "HUI ZNAET"
    }

    // useEffect(() => {
    //     if (props.waitingToReconnect) {
    //         return;
    //     }
    //
    //     if (props.wsClient) {
    //         const client = props.wsClient;
    //
    //         client.onmessage = (message: any) => {
    //             console.log("LOCAL MESSAGE", message.data)
    //             setToReset(!toReset)
    //         };
    //     }
    //
    // }, [props.waitingToReconnect]);

    useEffect(() => {
        let options__id = new Map()
        let localOptions = []
        let all_options = []

        if (props.user.projects.length > 0) {
            for (let pr = 0; pr < props.user.projects.length; pr++) {
                localOptions.push({value: props.user.projects[pr].id, label: props.user.projects[pr].name})
                options__id.set(props.user.projects[pr].id, options.length-1)
            }
        }
        // else {
        //     localOptions = [{value: null, label: "Без проекта"}]
        // }

        setOptions(localOptions)

        if (props.projects.length > 0) {
            for (let pr = 0; pr < props.projects.length; pr++) {
                all_options.push({value: props.projects[pr].id, label: props.projects[pr].name})
            }
            setAllOptions(all_options)
        }
    }, [props.projects]);

    const UpdateUserProjects = async () => {
        setLoading(true)
        let url = `${apiConfig.url}/auth/project?user_id=${props.user.id}`
        let project_ids = []
        for (let i=0; i<options.length; i++){
            project_ids.push(options[i].value)
        }
        const config = {
            method: 'patch',
            url: url,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            },
            data: project_ids
        };
        try {
            const response = await axios(config);
            setLoading(false)
        } catch (error) {
            console.error('error', error);
        }
    }

    const DeleteUser = async (user_id: any)=> {
        const config = {
            method: 'delete',
            url: `${apiConfig.url}/auth/user?user_id=${user_id}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            const response = await axios(config);
            props.getUsers(true, props.searchQuery)
        } catch (error) {
            console.error('error', error);
        }
    }

    // useEffect(() => {
    //     setToReset(!toReset)
    // }, []);

    return (
        <div key={props.user.id} style={{padding: 15, marginRight: "2%", position: "relative", borderRadius: 8, flexDirection: "column", display: "flex", flex: 1, backgroundColor: "white", boxShadow: "0px 5px 10px 2px rgba(34, 60, 80, 0.1)"}}>
            {
                loading &&
                <div
                    style={{
                        position: "absolute",
                        display: "flex",
                        flex: 1,
                        width: "100%",
                        height: "100%",
                        zIndex: 9,
                        top: 0,
                        left: 0,
                        alignItems: "center",
                        justifyContent: "center"
                }}>
                    <ClipLoader size={150} color={"grey"}/>
                </div>
            }
            <div style={{display: "flex", borderBottomWidth: 1, justifyContent: "space-between", marginBottom: 20, paddingBottom: 10}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div>
                        {props.user.name}
                    </div>
                    <div style={{color: props.user.online ? "green" : "grey"}}>
                        {props.user.online ? "online" : "offline"}
                    </div>
                </div>
                <button onClick={() => {DeleteUser(props.user.id)}} style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderRadius: 5,
                    paddingBottom: 5,
                    paddingTop: 5,
                    color: "white",
                    backgroundColor: "#FF4646"
                }}>
                    Удалить
                </button>
            </div>
            <div style={{display: "flex", flex: 1, marginBottom: 5, flexDirection: "column", justifyContent: "space-between", height: "100%"}}>
                <div style={{display: "flex", flex: 1, alignItems: "flex-start"}}>
                    <div style={{
                        display: "flex",
                        paddingTop: 5,
                        paddingRight: 10,
                        paddingLeft: 10,
                        paddingBottom: 5,
                        borderRadius: 5,
                        borderWidth: 2,
                        marginRight: 6,
                        borderColor: color,
                        color: color,
                    }}>
                        {word}
                    </div>
                    <div style={{paddingTop: 5}}>
                        {props.user.online && <Timer ref={timerRef} startTime={startTime2} color={color}/>}
                    </div>
                </div>
                <div style={{display: "flex", flex: 1, width: "100%", marginTop: 20}}>
                    <Select
                        isMulti
                        isClearable={false}
                        value={options}
                        options={allOptions}
                        onChange={e => {
                            setOptions(e)
                        }}
                    />
                </div>
                <div style={{display: "flex", flex: 1, alignItems: "flex-start"}}>
                    <button
                        style={{
                            display: "flex",
                            borderRadius: 4,
                            paddingRight: 10,
                            paddingLeft: 10,
                            alignItems: "center",
                            height: 40,
                            fontSize: 18,
                            marginTop: 20,
                            backgroundColor: "#0de31d",
                            color: "white"
                        }}
                        onClick={() => {
                            UpdateUserProjects()
                        }}
                    >
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    );
});


const Timer = forwardRef((props: any, parentRef) => {
    const [time, setTime] = useState(props.startTime * 100);

    // state to check stopwatch running or not
    const [isRunning, setIsRunning] = useState(true);

    useEffect(() => {
        let intervalId: any;
        if (isRunning) {
            // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
            intervalId = setInterval(() => setTime(time + 100), 1000);
        }
        return () => clearInterval(intervalId);
    }, [isRunning, time]);

    // useEffect(() => {
    //     reset()
    // }, [props.reset]);

    useImperativeHandle(parentRef, () => ({
        reset
    }), [])

    // Hours calculation
    const hours = Math.floor(time / 360000);

    // Minutes calculation
    const minutes = Math.floor((time % 360000) / 6000);

    // Seconds calculation
    const seconds = Math.floor((time % 6000) / 100);

    // Milliseconds calculation
    const milliseconds = time % 100;

    // Method to start and stop timer
    const startAndStop = () => {
        setIsRunning(!isRunning);
    };

    // Method to reset timer back to 0
    const reset = () => {
        setTime(0);
    };
    return (
        <div style={{fontSize: 18, color: props.color}}>
            <p className="stopwatch-time">
                {hours}:{minutes.toString().padStart(2, "0")}:
                {seconds.toString().padStart(2, "0")}
            </p>
        </div>
    );
});

export default UserCard;
