import React, {useEffect, useState} from 'react';
import {Link, useFetcher, useNavigate} from "react-router-dom";
import {fakeAuthProvider} from "../../components/auth/auth";
import StandardMenu from "../../components/standardMenu";
import axios from "axios"
import Switch from "react-switch";
import internal from "stream";
import Header from "../../components/header";
import Select, {ActionMeta, Options} from "react-select";
import {ClipLoader} from "react-spinners";
import MainMenu from "../../components/MainMenu";
import {apiConfig} from "../../config";

const ProjectsPage: React.FC = () => {
    const [responseProjects, setResponseProjects] = useState(null)
    const [responseProject, setResponseProject] = useState(null)
    const [selectedProject, setProject] = useState(null)

    const [selectOption1, setSelectOption1]: [any, any] = useState(null)
    const [selectOpt1, setSelectOpt1]: [any, any] = useState(false)
    const [selectOptValue1, setSelectValue1]: [any, any] = useState(1)

    const [selectOption2, setSelectOption2]: [any, any] = useState(null)
    const [selectOpt2, setSelectOpt2]: [any, any] = useState(false)
    const [selectOptValue2, setSelectValue2]: [any, any] = useState(1)

    const [selectOption3, setSelectOption3]: [any, any] = useState(null)
    const [selectOpt3, setSelectOpt3]: [any, any] = useState(false)
    const [selectOptValue3, setSelectValue3]: [any, any] = useState(1)

    const [Options, setOptions]: [any, any] = useState(null)
    const [name, setName] = useState('')
    const [leadUrl, setLeadUrl] = useState('')
    const [priority, setPriority] = useState('')
    const [countPhones, setCountPhones] = useState(-1)
    const [phonesLoading, setPhonesLoading] = useState(false)
    const [selectedPage, setPage] = useState(1)
    const [maxPages, setMaxPages] = useState(1)
    const [phonesPagination, setPhonesPagination] = useState({phones: [], pages: 0, page: 1})
    const [createModal, setCreateModal] = useState(false)
    const [selectOperators, setSelectOperators]: [any, any] = useState([])
    const [selectOperatorsAll, setSelectOperatorsAll]: [any, any] = useState([])
    const [selectPjSip, setSelectPjSip]: [any, any] = useState(null)
    const [selectPjSipAll, setSelectPjSipAll]: [any, any] = useState([])
    const [loading, setLoading]: [any, any] = useState(false)

    const  getUsers = async ()=> {
        const config = {
            method: 'get',
            url: `${apiConfig.url}/auth/list`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            return await axios(config);
        } catch (error) {
            return {
                data: []
            }
            console.error('error', error);
        }
    }
    const  getProjects = async ()=> {
        setLoading(true)
        const config = {
            method: 'get',
            url: `${apiConfig.url}/project/list`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            const response = await axios(config);

            await getPjSips()

            setResponseProjects(response.data)
            setLoading(false)
        } catch (error) {
            console.error('error', error);
        }
    }
    const  getProject = async (project_id: any)=> {
        setLoading(true)
        const config = {
            method: 'get',
            url: `${apiConfig.url}/project?project_id=${project_id}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            const response = await axios(config);

            let response_users = await getUsers()

            let options__id = new Map()

            let operatorsOptions = []
            for (let i = 0; i < response_users.data.length; i++) {
                operatorsOptions.push(
                    {value: response_users.data[i].id, label: response_users.data[i].name}
                )
                options__id.set(response_users.data[i].id, operatorsOptions.length-1)
            }

            let defaultOptions = []
            for (let i = 0; i < response.data.users.length; i++) {
                defaultOptions.push(
                    operatorsOptions[options__id.get(response.data.users[i].id)]
                )
            }

            setSelectOperators(defaultOptions)
            setSelectOperatorsAll(operatorsOptions)

            setResponseProject(response.data)
            setSelectPjSip(response.data.pjsip ? {value: response.data.pjsip.id, label: response.data.pjsip.name} : null)
            setName(response.data.name)
            setLeadUrl(response.data.lead_url ? response.data.lead_url : '')
            setPriority(response.data.priority)
            setOptions(response.data.dozvon_setting)
            // setIsActive(response.data.is_active)

            setSelectOption1(response.data.dozvon_setting.setting_data["Режим работы проекта"]["Progressive mode"])
            setSelectOpt1(response.data.dozvon_setting.setting_data["Режим работы проекта"]["Progressive mode"].is_active)
            setSelectValue1(response.data.dozvon_setting.setting_data["Режим работы проекта"]["Progressive mode"].value)

            setSelectOption2(response.data.dozvon_setting.setting_data["Режим работы проекта"]["Power dial"])
            setSelectOpt2(response.data.dozvon_setting.setting_data["Режим работы проекта"]["Power dial"].is_active)
            setSelectValue2(response.data.dozvon_setting.setting_data["Режим работы проекта"]["Power dial"].value)

            setSelectOption3(response.data.dozvon_setting.setting_data["Режим работы проекта"]["Predictive mode"])
            setSelectOpt3(response.data.dozvon_setting.setting_data["Режим работы проекта"]["Predictive mode"].is_active)
            setSelectValue3(response.data.dozvon_setting.setting_data["Режим работы проекта"]["Predictive mode"].value)

            console.log("GET PROJECT", response.data)
            setLoading(false)
        } catch (error) {
            console.error('error', error);
        }
    }
    const getPhonesCount = async (project_id: any)=> {
        setCountPhones(-1)
        const config = {
            method: 'get',
            url: `${apiConfig.url}/project/phones/count?project_id=${project_id}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            const response = await axios(config);
            console.log(response.data)
            setCountPhones(response.data)
        } catch (error) {
            console.error('error', error);
        }
    }
    const getPhones = async (project_id: any, page: any)=> {
        page = page - 1
        const config = {
            method: 'get',
            url: `${apiConfig.url}/project/phones?project_id=${project_id}&page=${page}&size=25`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            const response = await axios(config);
            console.log("PHONES", response.data)
            setPhonesPagination(response.data)
            setMaxPages(response.data.pages+1)
        } catch (error) {
            console.error('error', error);
        }
    }
    const getPjSips = async ()=> {
        const config = {
            method: 'get',
            url: `${apiConfig.url}/project/pjsip/list`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            const response = await axios(config);
            console.log("PJSIPS", response.data)
            let pjsipsOptions = []
            for (let i=0; i<response.data.length; i++) {
                pjsipsOptions.push(
                    {value: response.data[i].id, label: response.data[i].name}
                )
            }
            setSelectPjSipAll(pjsipsOptions)
        } catch (error) {
            console.error('error', error);
        }
    }
    const updateProject = async (project_id: any, name: any, operators: any)=> {
        setLoading(true)
        let user_ids = []
        for (let i of operators) {
            user_ids.push(i.value)
        }

        console.log(selectOption1)
        console.log(selectOption2, selectOption2.is_active)
        console.log(selectOption3)


        const config = {
            method: 'patch',
            url: `${apiConfig.url}/project`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            },
            data: {
                id: project_id,
                name: name,
                user_ids: user_ids,
                pjsip_id: selectPjSip.value,
                priority: priority,
                lead_url: leadUrl,
                dozvon_setting: {
                    id: Options.id,
                    name: Options.name,
                    setting_data: {
                        "Режим работы проекта": {
                            "Progressive mode": selectOption1,
                            "Power dial": selectOption2,
                            "Predictive mode": selectOption3
                        }
                    }
                }
            }
        };
        try {
            const response = await axios(config);
            setLoading(false)
        } catch (error) {
            console.error('error', error);
        }
    }

    const deletePhones = async (project_id: any)=> {
        const config = {
            method: 'delete',
            url: `${apiConfig.url}/project/phones?project_id=${project_id}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            const response = await axios(config);
            getPhonesCount(selectedProject)
            setPage(1)
            getPhones(selectedProject, selectedPage)


        } catch (error) {
            console.error('error', error);
        }
    }

    const getPhonesCsv = async (project_id: any)=> {
        const config = {
            method: 'get',
            url: `${apiConfig.url}/project/phones/csv?project_id=${project_id}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            const response = await axios(config);

            const blob = new Blob([response.data], { type: 'text/csv' });

            // Create a download link and trigger the download
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${responseProject ? responseProject["name"] : "none"}_phones.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.error('error', error);
        }
    }

    const deleteProject = async (project_id: any)=> {
        const config = {
            method: 'delete',
            url: `${apiConfig.url}/project?project_id=${project_id}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            const response = await axios(config);
            getProjects()
            setProject(null)

        } catch (error) {
            console.error('error', error);
        }
    }

    const OnClickItem = (id: any) => {
        getProject(id)
        getPhonesCount(id)

        setProject(id)
        setCreateModal(false)
    }

    // useEffect(() => {
    //     if (selectedProject) {
    //         getProject(selectedProject)
    //     }
    // }, [selectedProject])

    useEffect(() => {
        getProjects()
    }, [])


    useEffect(() => {
        if (selectedProject) {
            getPhones(selectedProject, selectedPage)
        }
    }, [selectedPage, selectedProject])


    let phones_li = []
    if (phonesPagination) {
        for (let i =0; i<phonesPagination.phones.length; i++){
            phones_li.push(
                <li key={i} style={{display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between"}}>
                    <div style={{marginRight: 20}}>
                        {phonesPagination.phones[i]["id"]}
                    </div>
                    <div style={{marginRight: 20}}>
                        {phonesPagination.phones[i]["phone"]}
                    </div>
                    <div>
                        {phonesPagination.phones[i]["created_at"]}
                    </div>
                </li>
            )
        }
    }

    const handleFileUpload = async (event: any) => {
        // get the selected file from the input
        const file = event.target.files[0];
        // create a new FormData object and append the file to it
        const formData = new FormData();
        formData.append("file", file);

        setPhonesLoading(true)

        const config = {
            method: 'post',
            url: `${apiConfig.url}/project/phones/csv?project_id=${selectedProject}`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            },
            data: formData
        };
        try {
            const response = await axios(config);
            console.log("FILES", response);
            setPhonesLoading(false)
            getPhonesCount(selectedProject)

            setPage(1)
            getPhones(selectedProject, selectedPage)

            event.target.value = null

        } catch (error) {
            console.error('error', error);
            setPhonesLoading(false)
        }
    }

    return (
        <div style={{display: "flex", flexDirection: "column", height: "100vh"}}>
        <Header title={"Проекты"}/>
        <div style={{display: "flex", flex: 1, flexDirection: "row"}}>
            <MainMenu />
            <div style={{display: "flex", flex: 1, padding: 20}}>
                <StandardMenu valuesList={responseProjects} onClickItem={OnClickItem} onClickCreate = {setCreateModal}/>
                {createModal && <CreateProjectComponent selectPjSipAll={selectPjSipAll} setCreateModal={setCreateModal} getProjects={getProjects}/>}
                {selectedProject && responseProject && !createModal &&
                    <div style={{display: "flex", flex: 1, paddingLeft: 20,}}>
                        <div style={{display: "flex", flex: 1, justifyContent: "space-between", flexDirection: "column", borderRightWidth: 1}}>
                            <div style={{display: "flex", flex: 1, justifyContent: "space-between", flexDirection: "row", marginRight: 20}}>
                                <div style={{display: "flex", flexDirection: "column", marginRight: 20}}>
                                    <div style={{marginBottom: 20}}>
                                        <div>
                                            Название:
                                        </div>
                                        <input
                                            style={{borderWidth: 1}}
                                            name={'name'}
                                            value={name}
                                            onChange={e => setName(e.target.value)}
                                        />
                                    </div>
                                    <div style={{marginBottom: 20}}>
                                        <div>
                                            Операторы
                                        </div>
                                        <Select
                                            value={selectOperators}
                                            options={selectOperatorsAll}
                                            isMulti
                                            onChange={(item) => {setSelectOperators(item)}}
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            Поставщик:
                                        </div>
                                        <Select
                                            value={selectPjSip}
                                            options={selectPjSipAll}
                                            onChange={(item) => {setSelectPjSip(item)}}
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            Ссылка формы:
                                        </div>
                                        <input
                                            style={{borderWidth: 1}}
                                            name={'leadUrl'}
                                            value={leadUrl}
                                            onChange={e => setLeadUrl(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            Приоритет:
                                        </div>
                                        <input
                                            style={{borderWidth: 1}}
                                            name={'priority'}
                                            value={priority}
                                            onChange={e => setPriority(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            {Options.name}:
                                        </div>
                                        <div>
                                            <div>
                                                Progressive
                                            </div>
                                            <input
                                                style={{borderWidth: 1}}
                                                name={'Option1'}
                                                value={selectOptValue1}
                                                onChange={e => {
                                                    setSelectValue1(e.target.value)
                                                    let new_opt = selectOption1
                                                    new_opt.value = e.target.value
                                                    setSelectOption1(new_opt)
                                                }}
                                            />
                                            <input
                                                type="checkbox"
                                                name="Option1_active"
                                                onChange={e => {
                                                    setSelectOpt1(!selectOpt1)
                                                    if (!selectOpt1) {
                                                        setSelectOpt2(false)
                                                        let new_opt2 = selectOption2
                                                        new_opt2.is_active = false
                                                        setSelectOption2(new_opt2)

                                                        setSelectOpt3(false)
                                                        let new_opt3 = selectOption3
                                                        new_opt3.is_active = false
                                                        setSelectOption3(new_opt3)
                                                    }

                                                    let new_opt = selectOption1
                                                    new_opt.is_active = !selectOpt1
                                                    setSelectOption1(new_opt)
                                                }}
                                                checked={selectOpt1}
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                Power
                                            </div>
                                            <input
                                                style={{borderWidth: 1}}
                                                name={'Option2'}
                                                value={selectOptValue2}
                                                onChange={e => {
                                                    setSelectValue2(e.target.value)
                                                    let new_opt = selectOption2
                                                    new_opt.value = e.target.value
                                                    setSelectOption2(new_opt)
                                                }}
                                            />
                                            <input
                                                type="checkbox"
                                                name="Option2_active"
                                                onChange={e => {
                                                    setSelectOpt2(!selectOpt2)
                                                    if (!selectOpt2) {
                                                        setSelectOpt1(false)
                                                        let new_opt1 = selectOption1
                                                        new_opt1.is_active = false
                                                        setSelectOption1(new_opt1)

                                                        setSelectOpt3(false)
                                                        let new_opt3 = selectOption3
                                                        new_opt3.is_active = false
                                                        setSelectOption3(new_opt3)
                                                    }

                                                    let new_opt = selectOption2
                                                    new_opt.is_active = !selectOpt2
                                                    setSelectOption2(new_opt)
                                                }}
                                                checked={selectOpt2}
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                Predictive
                                            </div>
                                            <input
                                                style={{borderWidth: 1}}
                                                name={'Option3'}
                                                value={selectOptValue3}
                                                onChange={e => {
                                                    setSelectValue3(e.target.value)
                                                    let new_opt = selectOption3
                                                    new_opt.value = e.target.value
                                                    setSelectOption3(new_opt)
                                                }}
                                            />
                                            <input
                                                type="checkbox"
                                                name="Option3_active"
                                                onChange={e => {
                                                    setSelectOpt3(!selectOpt3)
                                                    if (!selectOpt3) {
                                                        setSelectOpt1(false)
                                                        let new_opt1 = selectOption1
                                                        new_opt1.is_active = false
                                                        setSelectOption1(new_opt1)

                                                        setSelectOpt2(false)
                                                        let new_opt2 = selectOption2
                                                        new_opt2.is_active = false
                                                        setSelectOption2(new_opt2)
                                                    }

                                                    let new_opt = selectOption3
                                                    new_opt.is_active = !selectOpt3
                                                    setSelectOption3(new_opt)
                                                }}
                                                checked={selectOpt3}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{display: "flex", backgroundColor: "#FF4646", justifyContent: "center", alignItems: "center", maxHeight: 60}}>
                                    <button style={{display: "flex", flex: 1, paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, color: "white"}} onClick={() => {}}>
                                        Удалить проект
                                    </button>
                                </div>
                            </div>
                            <div style={{display: "flex", height: 50, justifyContent: "center"}}>
                                <button
                                    onClick={() => {updateProject(selectedProject, name, selectOperators).then(() => {getProject(selectedProject); getProjects()})}}
                                    style={{
                                        display: "flex",
                                        width: 200,
                                        height: 50,
                                        borderWidth: 1,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: "#1BFF2A",
                                        color: "white"
                                }}>
                                    Сохранить изменения
                                </button>
                            </div>
                        </div>
                        <div style={{display: "flex", flex: 1, flexDirection: "column", paddingLeft: 20}}>
                            <div>(Неиспользованных телефонов: {countPhones === -1 ? ('Загрузка...') : countPhones})</div>
                            <div style={{marginTop: 5, display: "flex", alignItems: "flex-start"}}>
                                <button
                                    onClick={() => {
                                        getPhonesCsv(selectedProject)
                                    }}
                                    style={{
                                        display: "flex",
                                        borderWidth: 3,
                                        borderRadius: 4,
                                        borderColor: "#09bd13",
                                        padding: 4,
                                        fontSize: 17,
                                        alignItems: "center",
                                        color: "#09bd13"
                                }}>
                                    Выгрузить
                                </button>
                            </div>
                            <div style={{display: "flex",justifyContent: "row"}}>
                                <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                                    <div style={{marginTop: 20}}>Добавить телефоны:</div>
                                    <input type="file" onChange={handleFileUpload} />
                                    {phonesLoading ? <div>Идёт загрузка телефонов</div> : null}
                                </div>
                                <div style={{display: "flex", flex: 1, alignItems: "center",justifyContent: "center"}}>
                                    <button onClick={() => {deletePhones(selectedProject)}} style={{display: "flex", paddingLeft: 10, paddingTop: 5, paddingBottom: 5, paddingRight: 10, backgroundColor: "#FF4646", color: "white"}}>
                                        Удалить все
                                    </button>
                                </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", marginRight: 20, borderWidth: 1, marginTop: 20}}>
                                <div style={{display: "flex", flex: 1}}>
                                    <ul style={{display: "flex", flex: 1, flexDirection: "column"}}>
                                        <li style={{display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between"}}>
                                            <div style={{}}>
                                                ID
                                            </div>
                                            <div style={{}}>
                                                PHONE
                                            </div>
                                            <div>
                                                CREATED AT
                                            </div>
                                        </li>
                                        {phones_li}
                                    </ul>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20, alignItems: "center", borderTopWidth: 1}}>
                                    <div style={{paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5}}>
                                        <button onClick={() => {selectedPage > 1 ? setPage(selectedPage-1) : setPage(selectedPage)}}>
                                            Предыдущая
                                        </button>
                                    </div>
                                    <div>
                                        {selectedPage}
                                    </div>
                                    <div style={{paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5}}>
                                        <button onClick={() => {selectedPage < maxPages - 1 ? setPage(selectedPage+1) : setPage(selectedPage)}}>
                                            Следующая
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
            {loading &&
                <div style={{
                    fontSize: 30,
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(100, 100, 100, 0.2)",
                    color: "white",
                    display: "flex",
                    paddingTop: "15%",
                    justifyContent: "center",
                }}>
                    <ClipLoader size={150} color={"black"}/>
                </div>
            }
        </div>
    );
};


export default ProjectsPage;


const CreateProjectComponent = (props: any) => {
    const [is_active, setIsActive] = useState(false)
    const [name, setName] = useState('')
    const [selectPjsip, setPjsip]: [any, any] = useState(null)


    const createProject = async (name: any, pjsip_id: number | null)=> {
        let url = `${apiConfig.url}/project?name=${name}`
        if (pjsip_id) {
            url += `&pjsip_id=${pjsip_id}`
        }
        const config = {
            method: 'post',
            url: url,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            const response = await axios(config);
            props.getProjects()
        } catch (error) {
            console.error('error', error);
        }
    }

    return (
        <div style={{display: "flex", flex: 1, justifyContent: "space-between", flexDirection: "column", borderRightWidth: 1, paddingLeft: 20}}>
            <div style={{display: "flex", flex: 1}}>
                <div style={{marginRight: 20}}>
                    <div>Название:</div><input style={{borderWidth: 1}} name={'name'} value={name} onChange={e => setName(e.target.value)} />
                </div>
                <div>
                    <div>Поставщик:</div>
                    <Select
                        value={selectPjsip}
                        options={props.selectPjSipAll}
                        onChange={(item) => {setPjsip(item)}}
                    />
                </div>
                {/*<div style={{marginRight: 20}}>*/}
                {/*    <div>Активность проекта:</div>*/}
                {/*    <Switch onChange={() => {setIsActive(!is_active)}} checked={is_active}/>*/}
                {/*</div>*/}
            </div>
            <div style={{display: "flex", height: 50, justifyContent: "center"}}>
                <button
                    onClick={() => {createProject(name, selectPjsip?.value); props.setCreateModal(false); props.getProjects()}}
                    style={{
                        display: "flex",
                        width: 200,
                        height: 50,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "white"
                    }}>
                    Создать проект
                </button>
            </div>
        </div>
    )
}


const downloadFile = (
    filePath: any,
    fileName = 'Example-PDF-file.pdf',
) => {
    fetch('https://cors-anywhere.herokuapp.com/' + filePath, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/pdf',
        },
    })
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));

            const link: any = document.createElement('a');
            link.href = url;
            link.download = fileName;

            document.body.appendChild(link);

            link.click();

            link.parentNode.removeChild(link);
        });
};
